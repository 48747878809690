import { useEffect } from 'react';
import { App } from '@capacitor/app';
import RatingModal from '../Pages/Login/RatingModal';

const useHardwareBackButton = (IsExit, handleBackButtonPress) => {
  useEffect(() => {
    const handleBackButton = (event) => {
      if (IsExit === 0) {
        handleBackButtonPress();
      } else {
       
        if (window.confirm("Are you sure you want to exit!?")) {
          // App.exitApp();
          <RatingModal/>
        }
      }
    };

    // Adding back button listener
    App.addListener('backButton', handleBackButton);

    // Removing back button listener on cleanup
    return () => {
      App.removeAllListeners('backButton')
    };
  }, [IsExit, handleBackButtonPress]);
};

export default useHardwareBackButton;
