import React, { useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { StatusBar } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';

function VideoDisplayModal({ showModal, handleClose, currentVideo }) {
    const iframeRef = useRef(null);

    useEffect(() => {
        if (Capacitor.getPlatform() !== 'web') {
            StatusBar.hide();  // Only hide status bar on native platforms
        }

        return () => {
            if (Capacitor.getPlatform() !== 'web') {
                StatusBar.hide();  // Only hide status bar on native platforms
            }
        };
    }, []);

    const enterFullscreen = async () => {
        const iframe = iframeRef.current;

        if (iframe) {
            if (iframe.requestFullscreen) {
                await iframe.requestFullscreen();
            } else if (iframe.mozRequestFullScreen) {
                await iframe.mozRequestFullScreen();
            } else if (iframe.webkitRequestFullscreen) {
                await iframe.webkitRequestFullscreen();
            } else if (iframe.msRequestFullscreen) {
                await iframe.msRequestFullscreen();
            }
        }
    };

    const handlePlay = () => {
        const iframe = iframeRef.current;
        if (iframe) {
            iframe.contentWindow.postMessage(
                '{"event":"command","func":"playVideo","args":""}',
                '*'
            );
        }
    };

    useEffect(() => {
        const iframe = iframeRef.current;
        if (iframe) {
            iframe.addEventListener('load', handlePlay);
        }

        return () => {
            if (iframe) {
                iframe.removeEventListener('load', handlePlay);
            }
        };
    }, []);

    // Function to handle the sharing of the video URL
    const handleShare = async () => {
        try {
            await navigator.clipboard.writeText(currentVideo); // Copy the video URL to clipboard
            alert('Video URL copied to clipboard!'); // Notify user
        } catch (error) {
            console.error('Error copying to clipboard:', error);
            alert('Failed to copy the URL to clipboard'); // Handle errors if clipboard access fails
        }
    };
    

    return (
        <Modal show={showModal} centered>
            <Modal.Body style={{ padding: 0 }}>
                <div
                    style={{
                        position: 'relative',
                        paddingBottom: '56.25%', // 16:9 aspect ratio
                        height: 0,
                    }}
                >
                    <iframe
                        ref={iframeRef}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                        }}
                        src={currentVideo}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                        allowFullScreen
                        onClick={enterFullscreen} // Click to enter fullscreen
                    ></iframe>
                    <button
                        style={{
                            backgroundColor: 'rgb(239 239 239)',
                            border: 'none',
                            borderRadius: '50%',
                            boxShadow: 'rgba(0, 0, 0, 0.4) 0px 0px 5px 0px',
                            color: 'rgb(0 0 0)',
                            fontSize: '16px',
                            height: '23px',
                            margin: '0px',
                            opacity: '1',
                            outline: '0px',
                            padding: '0px',
                            position: 'absolute',
                            right: '-10px',
                            top: '-10px',
                            width: '23px',
                        }}
                        onClick={handleClose}
                    >
                        <i className="fas fa-times"></i>
                    </button>
                    {/* Add the Share button */}
                    <button
                        style={{
                            backgroundColor: '#007bff',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            padding: '10px 15px',
                            position: 'absolute',
                            bottom: '-20px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            cursor: 'pointer',
                            boxShadow: 'rgba(0, 0, 0, 0.4) 0px 0px 5px 0px',
                        }}
                        onClick={handleShare}
                    >
                        <i className="fas fa-share-alt"></i> Share
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default VideoDisplayModal;
