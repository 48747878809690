import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EbookPdfUrl } from '../../Constants/Config';

const SingleBookPdf = () => {
  const { Id } = useParams();
  const navigate = useNavigate();
  const [PdfSrc, setPdfSrc] = useState(null);
  const [isShareModalOpen, setShareModalOpen] = useState(false); // Share modal state

  // Generate the PDF source URL
  useEffect(() => {
    if (Id?.length > 0) {
      const Pdf = EbookPdfUrl + Id;
      setPdfSrc(Pdf);
    }
    return () => {
      setPdfSrc(null);
    };
  }, [Id]);

  const appUrl = window.location.href;
  const shareTitle = "Check out this amazing ebook!";

  // Memoize computed values for sharing (useMemo is optional here but good for optimization)
  const encodedUrl = useMemo(() => encodeURIComponent(appUrl), [appUrl]);
  const encodedTitle = useMemo(() => encodeURIComponent(shareTitle), [shareTitle]);

  // Handle the share button click
  const handleShareClick = () => setShareModalOpen(true);
  const closeShareModal = () => setShareModalOpen(false);

  // Handle sharing the page URL
  const handleShare = async () => {
    const shareData = {
      title: shareTitle,  
      text: "Explore this ebook in our app!",
      url: appUrl,
    };

    if (navigator.share) {
      try {
        await navigator.share(shareData); // Use Web Share API if supported
      } catch (error) {
        console.error("Error sharing:", error);
        await navigator?.clipboard?.writeText(appUrl); // Fallback: Copy the URL to clipboard
        alert("Page link copied to clipboard!");
      }
    } else {
      await navigator.clipboard.writeText(appUrl); // Fallback for browsers that don't support Web Share API
      alert("Page link copied to clipboard!");
    }
  };

  return (
    <div>
      {/* Header with back button and share button */}
      <div className="flex justify-between items-center px-4 py-2 bg-gray-100">
  <button
    className="text-gray-600 hover:text-gray-800"
    onClick={() => navigate(-1)}
  >
    <i className="fas fa-chevron-left"></i> Back
  </button>
  <button
    className="text-gray-600 hover:text-gray-800"
    onClick={handleShareClick}
  >
    <i className="fa-solid fa-share-alt"></i> Share
  </button>
</div>


      {/* PDF Viewer */}
      {PdfSrc && (
        <div>
          <div style={{ overflow: 'hidden', height: '650px' }}>
            <iframe
              title="PDF Viewer"
              src={`https://docs.google.com/gview?url=${PdfSrc}&embedded=true`}
              width="100%"
              height="100%"
              style={{ border: "none" }}
              sandbox="allow-same-origin allow-scripts"
            ></iframe>
          </div>
        </div>
      )}

      {/* Share Modal */}
      {isShareModalOpen && (
        <div
          className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50"
          onClick={closeShareModal} // Close modal on clicking the backdrop
        >
          <div
            className="bg-white rounded-lg w-full max-w-sm p-4 relative"
            onClick={(e) => e.stopPropagation()} // Prevent modal close when clicking inside the modal
          >
            {/* Modal Header */}
            <h3 className="text-lg font-semibold text-center">Share</h3>
            <button
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
              onClick={closeShareModal} // Close modal on clicking the button
            >
              <i className="fas fa-times"></i>
            </button>

            {/* Social Media Icons */}
            <div className="flex justify-around mt-4">
              <a
                href={`https://wa.me/?text=${encodedTitle}%20${encodedUrl}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col items-center text-green-500 hover:text-green-600"
                aria-label="Share on WhatsApp"
              >
                <i className="fab fa-whatsapp text-3xl"></i>
                <span className="text-sm mt-1">WhatsApp</span>
              </a>
              <a
                href={`https://www.instagram.com/?url=${encodedUrl}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col items-center text-pink-500 hover:text-pink-600"
                aria-label="Share on Instagram"
              >
                <i className="fab fa-instagram text-3xl"></i>
                <span className="text-sm mt-1">Instagram</span>
              </a>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col items-center text-blue-500 hover:text-blue-600"
                aria-label="Share on Facebook"
              >
                <i className="fab fa-facebook text-3xl"></i>
                <span className="text-sm mt-1">Facebook</span>
              </a>
              <a
                href={`https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedUrl}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col items-center text-blue-400 hover:text-blue-500"
                aria-label="Share on Twitter"
              >
                <i className="fab fa-twitter text-3xl"></i>
                <span className="text-sm mt-1">Twitter</span>
              </a>
            </div>

            {/* Divider */}
            <div className="my-4 border-t border-gray-300"></div>

            {/* Bottom Section: URL with Copy Button */}
            <div className="flex items-center justify-between bg-gray-100 rounded p-2">
              <input
                type="text"
                value={appUrl}
                readOnly
                className="flex-1 text-gray-800 bg-transparent outline-none"
              />
              <button
                onClick={async () => {
                  await navigator.clipboard.writeText(appUrl);
                  alert("Link copied to clipboard!");
                }}
                className="ml-2 px-4 py-2 text-sm bg-blue-500 text-white rounded hover:bg-blue-600"
                aria-label="Copy the link"
              >
                Copy
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleBookPdf;
