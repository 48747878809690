import React, { useState, useEffect } from "react";
import { MdWorkspacePremium } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ArticleImgUrl } from "../../Constants/Config";
import { stripHtmlTags } from "../../lib/helper";
import { GetGroupByType } from "../../Redux/UserHome/action";
import { FormattedDate2 } from "../../Constants/FormattedDate";
import AllArticles from "./AllArticles";
import AllAudios from "./AllAudios";
import AllVideos from "./AllVideos";
import ImageGallery from "./ImageGallery";
import "../Css/AllGroup.css";
import AllEbooks from "./AllEbooks";
import BannerSlide from "../../Components/UserHome/BannerSlide";
import { Dropdown, NavDropdown } from "react-bootstrap";
import CombinedFooter from "../../Components/Footer/CombinedFooter";
import AllBanners from "./AllBanners";
import useHardwareBackButton from "../../Constants/HardwareBackButtonPress";
function AllType() {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [VideoModal, setVideoModal] = useState(false)
  const [AudioModal, setAudioModal] = useState(false)
  const [OpenImage, setOpenImage] = useState(false)
  const handleBackButtonPress=()=>{
    if (VideoModal||AudioModal||OpenImage) {
      setVideoModal(false)
      setAudioModal(false)
      setOpenImage(false)
    } else {
      navigate('/user-home')
    }
    
  }
  useHardwareBackButton(0,handleBackButtonPress)  
  const { Id } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [GroupType, setGroupType] = useState(null);

  const GetAllDrugData = useSelector(
    (state) => state.UserHomeReducer.GetAllGroup,
  );
  useEffect(() => {
    if (Id == 1) {
      setGroupType({
        Label: "Articles",
        Value: 1,
      });
    } else if (Id == 2) {
      setGroupType({ Label: "Videos", Value: 2 });
    } else if (Id == 3) {
      setGroupType({ Label: "Puzzles", Value: 3 });
    } else if (Id == 6) {
      setGroupType({
        Label: "Ebooks",
        Value: 6,
      });
    } else if (Id == 4) {
      setGroupType({ Label: "Posters", Value: 4 });
    }else if (Id == 8) {
      setGroupType({ Label: "Audios", Value: 8 });
    }
  }, [Id]);

  useEffect(() => {
    if (GroupType) {
      setSearchTerm('')
      dispatch(
        GetGroupByType({
          type: GroupType.Value,
        }),
      );
    }
  }, [GroupType]);

  const filteredArticles = GetAllDrugData?.filter((data) =>
  data.Heading.toLowerCase().includes(searchTerm.toLowerCase()) ||
  data.Keyword.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="ContentDisplayPage">
      <div className="Article-Modal" style={{flex:'1 1 0%'}}>
        <div className=" home-slider ">
          <div className="page-header-con">
            <button className="backbtn" onClick={() => navigate("/user-home")}>
              <i className="fas fa-chevron-left"></i>
            </button>
            {/* <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                style={{
                  position: "absolute",
                  right: "20px",
                  background: "#b78751",
                  border: "none",
                }}
              >
                {GroupType?.Label}
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{
                  background: "#f1dac1",
                  textAlign: "center",
                }}
              >
                <Dropdown.Item
                  className="dropdownItem"
                  onClick={() => setGroupType({ Label: "Articles", Value: 1 })}
                >
                  Articles
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdownItem"
                  onClick={() => setGroupType({ Label: "Videos", Value: 2 })}
                >
                  Videos
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdownItem"
                  onClick={() => setGroupType({ Label: "Gallery", Value: 3 })}
                >
                  Gallery
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdownItem"
                  onClick={() => setGroupType({ Label: "Ebooks", Value: 6 })}
                >
                  Ebooks
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdownItem"
                  onClick={() => setGroupType({ Label: "Banners", Value: 4 })}
                >
                  Banners
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
          </div>
        </div>

        <h2 className="page-heading">{GroupType?.Label}</h2>
        <div className="container">
          <div className="row justify-content-center mt-3 ">
            <div className="search-container">
              <div
                className="row w-100"
                style={{ justifyContent: "center", display: "flex" }}
              >
                <div
                  className="col-xl-3 col-lg-3 col-md-3 col-4"
                  style={{ paddingLeft: "0px !important" }}
                >
                  <select
                    style={{ borderRight: "none",appearance:'auto',paddingRight:'0px' }}
                    name="search-options"
                    id="search-select"
                    value={GroupType?.Value}
                    onChange={(e) => {
                      const selectedValue = parseInt(e.target.value); // Convert to integer
                      switch (selectedValue) {
                        case 1:
                          setGroupType({ Label: "Articles", Value: 1 });
                          break;
                        case 2:
                          setGroupType({ Label: "Videos", Value: 2 });
                          break;
                        case 3:
                          setGroupType({ Label: "Puzzles", Value: 3 });
                          break;
                        case 6:
                          setGroupType({ Label: "Ebooks", Value: 6 });
                          break;
                        case 4:
                          setGroupType({ Label: "Posters", Value: 4 });
                          break;
                        case 8:
                          setGroupType({ Label: "Audios", Value: 8 });
                          break;
                        default:
                          // Handle default case
                          break;
                      }
                    }}
                  >
                    <option value="1">Articles</option>
                    <option value="2">Videos</option>
                    <option value="3">Puzzles</option>
                    <option value="6">Ebooks</option>
                    <option value="4">Posters</option>
                    <option value="8">Audios</option>
                  </select>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-6 col-6">
                  <input
                    style={{
                      borderTopRightRadius: "20px",
                      borderBottomRightRadius: "20px",

                    }}
                    type="text"
                    placeholder={`Search ${GroupType?.Label}...`}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                {/* <div className="col-xl-2 col-lg-2 col-md-3 col-2"><button><i className="fas fa-search"></i> <div>Search</div></button></div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          {filteredArticles?.length === 0 ? (
            <p
              style={{
                textAlign: "center",
                color: "gray",
                fontSize: "18px",
                marginTop: "20px",
              }}
            >
              No data available.
            </p>
          ) : (
            <>
              {GroupType?.Value === 1 && (
                <AllArticles filteredArticles={filteredArticles} />
              )}
              {GroupType?.Value === 2 && (
                <AllVideos filteredArticles={filteredArticles} VideoModal={VideoModal} setVideoModal={setVideoModal}/>
              )}
              {GroupType?.Value === 3 && (
                <ImageGallery filteredArticles={filteredArticles}  OpenImage={OpenImage} setOpenImage={setOpenImage}/>
              )}
              {GroupType?.Value === 6 && (
                <AllEbooks filteredArticles={filteredArticles} />
              )}
              {GroupType?.Value === 4 && (
                  <AllBanners filteredArticles={filteredArticles} OpenImage={OpenImage} setOpenImage={setOpenImage}/>
              )}
              {GroupType?.Value === 8 && (
                  <AllAudios filteredArticles={filteredArticles} AudioModal={AudioModal} setAudioModal={setAudioModal}/>
              )}
            </>
          )}
        </div>
      </div>

      <CombinedFooter />
    </div>
  );
}

export default AllType;
