import React, { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IoIosPlayCircle } from 'react-icons/io';
import { MdClose, MdVolumeOff, MdVolumeUp } from 'react-icons/md';
import { shortsUrl } from '../../Constants/Config';

function ShortsModal(props) {
  const [muted, setMuted] = useState(false);
  const [paused, setPaused] = useState(false);
  const ShortsRef = useRef(null);

    console.log(props.showShortsModal,"prpr");
  // Ensure ShortsData is not null or undefined
  const shortsData = props.ShortsData || [];
  
  // Generate a random index only if ShortsData is not empty
  const randomIndex = shortsData.length > 0 ? Math.floor(Math.random() * shortsData.length) : -1;

  // If shortsData is empty or null, handle the error gracefully
  const videoUrl = randomIndex !== -1 ? shortsData[randomIndex].VideoUrl : null;

  console.log(videoUrl, 'vdo');

  const togglePlayPause = () => {
    if (ShortsRef.current.paused) {
      ShortsRef.current.play();
      setPaused(false);
    } else {
      ShortsRef.current.pause();
      setPaused(true);
    }
  };

  const toggleMute = () => {
    setMuted((prevMuted) => !prevMuted);
  };

  return (
    <Modal
      show={props.showShortsModal}
      onHide={props.handleCloseModal}
      size="lg"
      fullscreen
    >
      <Modal.Body style={{ padding: '0px', overflow: 'hidden' }}>
        <div className="mx-auto flex h-dvh w-full flex-col items-center sm:max-w-[420px]">
          <div className="relative mb-1 w-full flex-auto rounded-md bg-zinc-700/20">
            {paused ? (
              <IoIosPlayCircle className="h-28 w-28 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 fill-darkBrown rounded-full bg-white" />
            ) : null}

            {/* Close Button */}
            <div
              className="absolute left-2 top-2 z-50 cursor-pointer rounded-full bg-darkBrown"
              onClick={() => props.handleCloseModal()}
            >
              <MdClose className="h-8 w-8 text-white" />
            </div>

            <div className="flex h-full w-full items-center justify-center">
              {videoUrl ? (
                <video
                  ref={ShortsRef}
                  src={`${shortsUrl}${videoUrl}`} // Concatenate the base URL with the video path
                  autoPlay
                  muted={muted}
                  className="h-dvh w-auto bg-contain"
                  onClick={togglePlayPause}
                  onEnded={props.handleCloseModal}
                ></video>
              ) : (
                <span>Something went wrong!!!</span>
              )}
            </div>

            {/* Mute Button */}
            <div
              className="absolute right-2 top-2 z-50 cursor-pointer rounded-full bg-darkBrown"
              onClick={toggleMute}
            >
              {muted ? (
                <MdVolumeOff className="h-8 w-8 text-white" />
              ) : (
                <MdVolumeUp className="h-8 w-8 text-white" />
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ShortsModal;
