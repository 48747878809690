import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { ArticleImgUrl } from "../../Constants/Config";
import { formattedDate } from "../../Constants/FormattedDate";
import { getSession } from "../../lib/session";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { htmlToPlainText } from "../../lib/helper";
function Articles(props) {
  const session = getSession();
  const navigate = useNavigate();
  // Trim Description
  const trimText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.slice(0, maxLength) + "..."; // Truncate and add ellipsis
    }
  };

  const stripHtmlTags = (html, maxLength) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    const plainText = doc.body.textContent || "";
    return trimText(plainText, maxLength);
  };

  const settings = {
    // dots: true,
    slidesToShow: 4,
    arrows: true,
    refresh: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          arrows: true,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
    ],
  };

  //Single Article

  const [SelectedArticleData, setSelectedArticleData] = useState([]);

  const ArticleClick = (Data) => {
    const isPremiumUser = session.isPremiumUser;
    if (isPremiumUser || Data.IsPremium !== 1) {
      navigate(`/Article/${Data.Id}`);
    } else {
      toast.error("Please purchase a package");
    }
  };

  return (
    <>
      {props.ArticleData?.length > 0 && (
        <div className="articles-wrap">
          {/* <img src={LeafRight} className="leaf-right" alt="leaf" /> */}
          <div className="container">
            <div className="row" style={{ clear: "both", display: "flex" }}>
              <div className="col-lg-10 col-md-8 col-sm-12" onClick={() =>
                      navigate(`/Group/${props.GroupData.Id}/Type/10`)
                    }>
                <h2 className="Group-Heading">{props.GroupData.Heading} </h2>
              </div>
              {props.ArticleData?.length > 4 && (
                <div className="col-lg-2 col-md-4 ">
                  <button
                    className="viewall-btn"
                    onClick={() =>
                      navigate(`/Group/${props.GroupData.Id}/Type/1`)
                    }
                  >
                    View All
                  </button>
                </div>
              )}
            </div>

            <Slider {...settings}>
              {props.ArticleData?.map((data, index) => (
                <div className="col-lg-3 article-items-parent" key={index}>
                  <button
                    className={`article-item ${data.IsPremium === 1 ? "premium-icon" : ""} ${data.IsCopyRight === 1 ? "copy-icon" : ""}`}
                    onClick={() => ArticleClick(data)}
                  >
                    <span className="Article-span">
                      <img
                        src={ArticleImgUrl + data.ImageUrl}
                        alt="peacegate"
                      />
                      <font className="link-icon">
                        <i className="fas fa-link"></i>
                      </font>
                      <h6>
                        <font>{formattedDate(data.Date)}</font>
                      </h6>
                    </span>
                    <div className="article-content">
                      <h5 >{data.Heading}</h5>
                      {/* <p
                        className="line-clamp-5"
                        style={{ textAlign: "justify" }}
                      >
                        {htmlToPlainText(data.Description)}
                      </p> */}
                      
                    </div>
                  </button>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )}
    </>
  );
}

export default Articles;
