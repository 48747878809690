import React, { memo, useCallback, useEffect, useMemo, useState, useRef } from "react";
import { redirect, useNavigate, useSearchParams } from "react-router-dom";
import MobileHomeScreen from "../UserHome/MobileHomeScreen";
import axios from "axios";
import Config from "../../Constants/Config";
import toast from "react-hot-toast";
import { searchTypes, typeMapper } from "../../lib/helper";
import { isPremiumUser } from "../../lib/session";
import ArticleSearch, { ArticleLoader } from "./ArticleSearch";
import ImageSearch from "./ImageSearch";
import { Document } from "react-pdf";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import ShortsSearch from "./ShortsSearch";
import VideosSearch, { VideosLoader } from "./VideosSearch";
import AudioSearch from "./AudioSearch";
import AnnouncementSearch from "./AnnouncementSearch";
import BannerSearch from "./BannerSearch";
import PrithviLogo from "../../img/the-prithvi-logo.png";
import { IoHome } from "react-icons/io5";
import { PiSmileySadLight } from "react-icons/pi";

const fetchSearchResults = async ({ queryKey }) => {
  const [_key, { query, isPremium }] = queryKey;
  const response = await axios.get(
    `${Config.ApiUrl}GeneralSearch?KeyWord=${query ? query : ""}&IsPremium=${Number(isPremium ?? false)}`,
  );
  return response.data;
};

const Search = () => {
  const redirect = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  let isPremium = isPremiumUser();

  const [query, setQuery] = useState(searchParams.get("q") || "");
  const [debouncedQuery, setDebouncedQuery] = useState(query);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  let [filter, setFilter] = useState({ type: 0, Name: "All" });

  const { data, status, error, isFetching } = useQuery({
    queryKey: ["searchResults", { query: debouncedQuery, isPremium }],
    queryFn: fetchSearchResults,
  });

  // Effect for debouncing the API call
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
          !inputRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setQuery(value);
    setSearchParams((ref) => ({ ...ref, [name]: value }));
    setIsDropdownOpen(true);
  };

  const displayData = useMemo(() => {
    if (status === "success") {
      const groupedData = data.reduce((acc, item) => {
        if (filter.type === 0) {
          if (item.TypeId != 6) {
            if (!acc[item.TypeId]) {
              acc[item.TypeId] = [];
            }
            acc[item.TypeId].push(...item.Data);
          }
        } else if (item.TypeId === filter.type) {
          if (!acc[item.TypeId]) {
            acc[item.TypeId] = [];
          }
          acc[item.TypeId].push(...item.Data);
        }
        return acc;
      }, {});

      return groupedData;
    }
    return {};
  }, [filter, data, status]);

  // Flatten displayData for dropdown suggestions
  const suggestions = useMemo(() => {
    return Object.values(displayData).flat();
  }, [displayData]);

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion.Name || suggestion.Title || suggestion.text || '');
    setSearchParams((ref) => ({ ...ref, q: suggestion.Name || suggestion.Title || suggestion.text || '' }));
    setIsDropdownOpen(false);
  };

  const memorizedDataArray = useMemo(() => {
    return Object.keys(displayData);
  }, [displayData]);

  const availableFilters = useMemo(() => {
    if (status === "success") {
      const groupedData = data.reduce((acc, item) => {
        if (item.TypeId != 6) {
          if (!acc[item.TypeId]) {
            acc[item.TypeId] = [];
          }
          acc[item.TypeId].push(...item.Data);
        }
        return acc;
      }, {});
      return Object.keys(groupedData);
    }
    return [];
  }, [data, status]);

  return (
    <div className="flex h-full min-h-screen w-full flex-col overflow-auto">
      <div className="flex h-[40px] items-center justify-between bg-brown px-2.5">
        <div style={{ height: "40px", width: "fit-content", float: "left" }}>
          <img
            src={PrithviLogo}
            alt="Prithvi Logo"
            style={{ width: "auto", height: "100%" }}
          />
        </div>
        <div>
          <IoHome
            className="h-7 w-7 text-white"
            onClick={() => redirect("/user-home")}
          />
        </div>
      </div>
      
      {/* Search Input with Dropdown */}
      <div className="px-4 pb-3 pt-10">
        <div className="relative w-full">
          <input
            ref={inputRef}
            className="w-full rounded-full border border-gray-300 px-4 py-2 focus:border-brown focus:outline-none"
            type="text"
            name="q"
            value={query}
            onChange={handleInputChange}
            onFocus={() => setIsDropdownOpen(true)}
            placeholder="Search..."
          />
          
          {isDropdownOpen && suggestions.length > 0 && (
            <div 
              ref={dropdownRef}
              className="absolute z-50 mt-1 max-h-60 w-full overflow-y-auto rounded-lg border border-gray-200 bg-white shadow-lg"
            >
              {suggestions.map((item, index) => (
                <div
                  key={index}
                  className="cursor-pointer border-b border-gray-100 px-4 py-2 hover:bg-gray-100"
                  onClick={() => handleSuggestionClick(item)}
                >
                  <div className="font-medium">
                    {item.Name || item.Title || item.text || 'Untitled'}
                  </div>
                  {item.Description && (
                    <div className="text-sm text-gray-600 line-clamp-1">
                      {item.Description}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="mx-4 overflow-x-hidden pb-6">
        <div className="flex w-full items-center space-x-2.5 overflow-x-auto py-2 scrollbar-hide">
          {searchTypes.map((el) => (
            <div
              key={el.type}
              className={`flex w-fit cursor-pointer items-center justify-center rounded-full border border-black px-3 py-1 text-sm tracking-widest text-gray-500 ${
                filter.type === el.type ? "border-2 border-zinc-900 bg-stone-200" : ""
              }`}
              onClick={() => setFilter(el)}
            >
              {el.Name}
            </div>
          ))}
        </div>
      </div>

      <div className="mx-4 h-full flex-1">
        {status === "pending" ? (
          <LoadingSkeleton type={filter.type} />
        ) : memorizedDataArray.length ? (
          memorizedDataArray.map((key, _) => (
            <SearchResults
              rows={displayData[key]}
              type={key}
              filter={filter}
              key={_}
            />
          ))
        ) : (
          <NothingToShow setFilter={setFilter} filters={availableFilters} />
        )}
      </div>
    </div>
  );
};
export default Search;

const ShowAllComponents = memo(({ data, type }) => {
  switch (type) {
    case 1:
      return <ArticleSearch data={data} />;
    case 3:
      return <ImageSearch data={data} />;
    default:
      return (
        <div className="">
          <div>
            {data.Name} of type {type}
          </div>
          <div>Description of a larger length goes here.....</div>
        </div>
      );
  }
});

export const LoadingSkeleton = ({ type }) => {
  switch (type) {
    case "1":
      return <ArticleLoader />;
    case "2":
      return <VideosLoader />;
    case "6":
      return <div>loading...</div>;
    default:
      return <VideosLoader />;
  }
};

const SearchResults = ({ rows, type, filter }) => {
  switch (type) {
    case "1":
      return <ArticleSearch rows={rows} />;
    case "2":
      return <VideosSearch rows={rows} />;
    case "3":
      return <ImageSearch rows={rows} />;
    case "4":
      return <BannerSearch rows={rows} />;
    case "5":
      return <AnnouncementSearch rows={rows} />;
    case "7":
      return <ShortsSearch rows={rows} filter={filter} />;
    case "8":
      return <AudioSearch rows={rows} />;
    default:
      return <div>something is off...</div>;
  }
};

const NothingToShow = ({ filters, setFilter }) => {
  return (
    <div className="flex h-full w-full flex-1 flex-col items-center justify-center">
      <div className="mb-5 flex flex-col items-center justify-center">
        <PiSmileySadLight className="mb-2 h-9 w-9" />
        <div>Nothing Found!</div>
      </div>
      {/* <div className="flex items-center justify-center space-x-3">
                {filters.map((el) => {
                    return (
                        <div className="rounded-full bg-cyan-50 px-2 py-1 text-sm">
                            {typeMapper(el)}
                        </div>
                    );
                })}
            </div> */}
    </div>
  );
};
