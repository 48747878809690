import React, { useEffect, useState } from 'react';
import AnnouncementSlide from '../../Components/UserHome/AnnouncementSlide';
import Articles from '../../Components/UserHome/Articles';
import AskGuru from '../../Components/UserHome/AskGuru';
import Header from '../../Components/UserHome/Header';
import TopBanner from '../../Components/UserHome/TopBanner';
import '../Css/Desktop.css';
import Forum from '../../Components/UserHome/Forum';
import VideoSlide from '../../Components/UserHome/VideoSlide';
import { useDispatch, useSelector } from 'react-redux';
import { GetArticle, GetPrincipleGroup, PushNotification } from '../../Redux/UserHome/action';
import ImageSlide from '../../Components/UserHome/ImageSlide';
import BannerSlide from '../../Components/UserHome/BannerSlide';
import Ebooks from '../../Components/UserHome/Ebooks';
import { getSession } from "../../lib/session";
import DrugDataBase from '../../Components/UserHome/DrugDataBase';
import FooterTopWrapper from '../../Components/UserHome/FooterTopWrapper';
import CombinedFooter from '../../Components/Footer/CombinedFooter';
import AudioSlide from '../../Components/UserHome/AudioSlide';
function DesktopScreen() {
	const GetPrincipleGroupData = useSelector(
		(state) => state.UserHomeReducer.GetPrincipleGroup,
	);
	const AllData = useSelector((state) => state.UserHomeReducer.GetArticle);
	const session=getSession()
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(GetPrincipleGroup());
		dispatch(GetArticle());
	}, []);
	console.log(GetPrincipleGroupData,"")
	const OrderedPrincipleGroup = GetPrincipleGroupData?.map((item) => {
		if (item.Type === 1) {
			const ArticleData = AllData?.filter(
				(data) => data.GroupId === item.Id,
			);
			return (
				<Articles
					key={item.Id}
					GroupData={item}
					ArticleData={ArticleData}
				/>
			);
		} else if (item.Type === 2) {
			const VideoData = AllData?.filter(
				(data) => data.GroupId === item.Id,
			);
			return (
				<VideoSlide
					key={item.Id}
					GroupData={item}
					VideoData={VideoData}
				/>
			);
		} else if (item.Type === 3) {
			const ImageData = AllData?.filter(
				(data) => data.GroupId === item.Id,
			);
			return (
				<ImageSlide
					key={item.Id}
					GroupData={item}
					ImageData={ImageData}
				/>
			);
		} else if (item.Type === 4) {
			const BannerData = AllData?.filter(
				(data) => data.GroupId === item.Id,
			);
			return (
				<BannerSlide
					key={item.Id}
					GroupData={item}
					BannerData={BannerData}
				/>
			);
		}else if(item.Type === 6){
			const EbookData = AllData?.filter(
				(data) => data.GroupId === item.Id,
			);
			return (
				<Ebooks
					key={item.Id}
					GroupData={item}
					EbookData={EbookData}
				/>
			);
		}else if (item.Type === 8) {
			const AudioData = AllData?.filter(
				(data) => data.GroupId === item.Id,
			);
			return (
				<AudioSlide
					key={item.Id}
					GroupData={item}
					AudioData={AudioData}
				/>
			);
		}
		 else{
			return null;
		}
	});


	  useEffect(() => {
		const handleScroll = () => {
		  if (window.scrollY > 90) {
			setNavClass('f-nav');
		  } else {
			setNavClass('');
		  }
		};
	    if(window){
			window.scrollTo({
				top: 0, // Scrolls vertically to the top
				behavior: 'smooth'
			  });
		}
		window.addEventListener('scroll', handleScroll);
		return () => {
		  window.removeEventListener('scroll', handleScroll);
		};
	  }, []);
	
	  const [navClass, setNavClass] = useState('');
	return (
		<body  className={`${navClass}`}>
			<Header />
			<TopBanner />
			<AnnouncementSlide />
			{OrderedPrincipleGroup}
			<AskGuru />
			<DrugDataBase/>
			<Forum />
			<FooterTopWrapper/>
			<CombinedFooter/>
		</body>
	);
}

export default DesktopScreen;
