import React from 'react'
import { useState } from 'react';
import toast from 'react-hot-toast';
import { MdWorkspacePremium } from 'react-icons/md';
import AudioPlayModal from '../../Components/Modals/AudioPlayModal';
import VideoDisplayModal from '../../Components/Modals/VideoDisplayModal';
import { AudioUrl, VideoThumbUrl, VideoUrl } from '../../Constants/Config';
import { getSession } from '../../lib/session';

function AllAudiosNew(props) {
  const session = getSession();
    const audioData = props.filteredArticles || [];
    const [modalData, setModalData] = useState(null); 
    const handleCloseModal = () => props.setAudioModal(false);
    const toggleAudioState = (Data) => {
      const isPremiumUser = session.isPremiumUser;
      const canAccessAudio = isPremiumUser || Data.IsPremium !== 1;
      if (!canAccessAudio) {
        toast.error("Please purchase a package");
        return;
      }
      // If access is granted, set the modal data and open the modal
      setModalData(Data);
      props.setAudioModal(true);
    };

  return (
    <>  
     <AudioPlayModal showModal={props.AudioModal} handleCloseModal={handleCloseModal} AudioData={modalData}/>
    <div className="row trendingvideos" >

    {audioData?.map((data, index) => (
      <div
        key={index}
        className="col-lg-3 col-md-6 col-sm-12 video-item"
      >
                  <button
                    className={`trendvideo-item  ${data.IsPremium === 1 ? "premium-icon" : ""} ${data.IsCopyRight === 1 ? "copy-icon" : ""}`}
                    style={{ position: "relative" }}
                    onClick={() => toggleAudioState(data)}
                  >
                    <span className="Videospan premium-icon-span">
                      <img
                        src={VideoThumbUrl + data.ThumbImg}
                        alt="uploaded video"
                        style={{ width: "100%" }}
                      />
                    </span>
                    <div className="videocontent">
                      <font className="pulse-btn">
                        <i className="fas fa-play" />
                      </font>
                      <h6>{data.Heading}</h6>
                    </div>

                  </button>
                </div>
    ))}
  </div></>
  )
}

export default AllAudiosNew