import React, { useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { audioState } from '../../audioState';
import { AudioUrl } from '../../Constants/Config';

function AudioPlayModal({ showModal, handleCloseModal, AudioData }) {
    const [audioData, setAudioData] = useRecoilState(audioState);
    const audioRef = useRef(null);

    // Function to extract iframe URL from embed link
    function extractIframeSrc(VideoUrl) {
        const match = VideoUrl.match(/src="([^"]+)"/);
        return match ? match[1] : null;
    }

    useEffect(() => {
        console.log("State", audioData);
        if (AudioData?.AudioSecretKey) {
            const isYouTubeLink =
                AudioData.AudioSecretKey.includes('youtube.com') ||
                AudioData.AudioSecretKey.includes('youtube');
            const isZohoWorkdrive =
                AudioData.AudioSecretKey.includes('zohoexternal.com');

            var audioSrc = isYouTubeLink
                ? `https://www.youtube.com/embed/${AudioData.AudioSecretKey.split('v=')[1]}?autoplay=1`
                : isZohoWorkdrive
                  ? AudioData.AudioSecretKey
                  : `${AudioUrl}${AudioData.AudioSecretKey}`;
            console.log(audioSrc, 'src');
            const currentTime = audioData?.currentTime || 0;
            console.log(currentTime, "current");

            setAudioData((prev) => ({
                ...prev,
                audioSrc: audioSrc,
                isPlaying: true,
                isMinimized: false,
                currentTime: currentTime || 0,
            }));
        }
    }, [AudioData, setAudioData]);

    const iframeString = AudioData?.VideoUrl;

    function extractUrl(iframeString) {
        if (!iframeString) return null;
        const regex = /src=["']([^"']+)["']/;
        const match = iframeString.match(regex);
        return match ? match[1] : null;
    }

    const url = extractUrl(iframeString);

    const handleMinimize = () => {
        const currentTime = audioRef.current?.currentTime || 0;
        console.log(currentTime, "hand");
        setAudioData((prev) => ({
            ...prev,
            isMinimized: true,
            currentTime: currentTime,
            videoUrl: url,
            isPlaying: true,
            isMuted: false,
        }));
    };

    const handleClose = () => {
        setAudioData((prev) => ({
            ...prev,
            isPlaying: false,
            isMinimized: false,
        }));

        handleCloseModal();
        window.location.reload();
    };

    const handleShare = async () => {
        // Generate the full URL to share
        const fullUrl = `${window.location.origin}/audio/${AudioData?.AudioSecretKey}`; // Adjust as per your app's URL structure

        if (!fullUrl) {
            alert("No URL available to share!");
            return;
        }

        try {
            await navigator.clipboard.writeText(fullUrl); // Copy the URL to clipboard
            alert('Audio/Video URL copied to clipboard!'); // Notify user
        } catch (error) {
            console.error('Error copying to clipboard:', error);
            alert('Failed to copy the URL to clipboard'); // Handle errors if clipboard access fails
        }
    };

    return (
        <>
            {!audioData.isMinimized && (
                <Modal show={showModal} onHide={handleClose} centered>
                    <Modal.Body style={{ position: 'relative', padding: '20px' }}>
                        {AudioData?.VideoUrl ? (
                            <iframe
                                ref={audioRef}
                                src={url}
                                autoPlay={audioData.isPlaying}
                                style={{ width: '100%', height: '360px' }}
                            />
                        ) : audioData?.audioSrc ? (
                            <audio
                                ref={audioRef}
                                src={audioData.audioSrc}
                                autoPlay={audioData.isPlaying}
                                controls
                                onLoadedMetadata={(e) => {
                                    e.target.currentTime = audioData?.currentTime || 0; // Set audio position from state
                                }}
                                onEnded={() => setAudioData((prev) => ({
                                    ...prev,
                                    isPlaying: false
                                }))}
                                style={{ width: '100%', height: '60px' }}
                            />
                        ) : null}

                        <button
                            onClick={handleMinimize}
                            style={{ position: 'absolute', top: '10px', right: '10px' }}
                        >
                            <i className="fas fa-minus" />
                        </button>
                        <button
                            onClick={handleClose}
                            style={{ position: 'absolute', top: '10px', right: '50px' }}
                        >
                            <i className="fas fa-times" />
                        </button>
                        <button
                            style={{
                                backgroundColor: '#007bff',
                                color: 'white',
                                border: 'none',
                                borderRadius: '4px',
                                padding: '10px 15px',
                                position: 'absolute',
                                bottom: '-20px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                cursor: 'pointer',
                                boxShadow: 'rgba(0, 0, 0, 0.4) 0px 0px 5px 0px',
                            }}
                            onClick={handleShare}
                        >
                            <i className="fas fa-share-alt"></i> Share
                        </button>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
}

export default AudioPlayModal;
