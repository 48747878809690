import React from 'react';
import { useNavigate } from 'react-router-dom';
import CombinedFooter from '../../Components/Footer/CombinedFooter';
import useHardwareBackButton from '../../Constants/HardwareBackButtonPress';

const Pricing = () => {
    const redirect = useNavigate();
    const handleBackButtonPress = () => {
        redirect('/user-home');
    };
    useHardwareBackButton(0, handleBackButtonPress);

    return (
        <div className="flex flex-col h-screen bg-gray-50">
            {/* Sticky Navbar */}
            <div className="relative w-full bg-[#ad7b42] shadow-md z-[999]">
                <div className="flex justify-between items-center p-4">
                    <button
                        className="text-white text-lg font-bold"
                        onClick={() => redirect('/user-home')}
                    >
                        <i className="fas fa-chevron-left"></i>
                    </button>
                    <div className="flex-1 text-center">
                        <h2 className="text-white font-semibold text-lg">Pricing</h2>
                    </div>
                    <div></div> {/* Empty div to balance the layout */}
                </div>
            </div>

            {/* Pricing Section */}
            <div className="container mx-auto py-8 px-4 flex flex-col items-center">
                <div className="w-full max-w-3xl bg-white shadow-lg rounded-lg p-6 space-y-6">
                    <h1
                        id="pricing-policy"
                        className="text-3xl font-bold text-gray-900 tracking-tight mb-6"
                    >
                        Our Pricing Policy
                    </h1>

                    {/* Free Plan */}
                    <div className="bg-gradient-to-r from-[#ad7b42] to-[#f1dac1] p-4 rounded-lg shadow-md hover:scale-105 hover:shadow-xl transition-all duration-300 ease-in-out">
                        <h3 className="text-xl font-semibold text--800 mb-2">1. Free Plan</h3>
                        <p className="leading-7 text-grey">
                          <li>Limited access to articles and videos. This plan allows you to explore the basics.</li>
                        </p>
                    </div>

                    {/* Subscription Plans */}
                    <div className="bg-gradient-to-r from-[#f1dac1] to-[#ad7b42] p-6 rounded-lg shadow-lg hover:scale-105 hover:shadow-xl transition-all duration-300 ease-in-out">
                        <h3 className="text-xl font-semibold text-black mb-4">2. Subscription Plans</h3>
                        <ul className="list-disc pl-6 space-y-2 text-grey">
                            <li>₹200/month or ₹2,000/year. Full access to all content.</li>
                        </ul>
                    </div>

                    {/* Pay-Per-Use Plans */}
                    <div className="bg-gradient-to-r from-[#ad7b42] to-[#f1dac1] p-4 rounded-lg shadow-md hover:scale-105 hover:shadow-xl transition-all duration-300 ease-in-out">
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">3. Pay-Per-Use</h3>
                        <ul className="list-disc pl-6 space-y-2 text-grey">
                            <li>Workshops: ₹100–₹500 per session. Choose the session that fits your needs.</li>
                        </ul>
                    </div>

                    {/* Accessibility Note */}
                    <div className="bg-gradient-to-r from-[#f1dac1] to-[#ad7b42] p-4 rounded-lg shadow-md hover:scale-105 hover:shadow-xl transition-all duration-300 ease-in-out">
                        <p className="leading-7 text-grey">
                            Our affordable pricing ensures accessibility for students and professionals alike.
                        </p>
                    </div>
                </div>
            </div>

            {/* Sticky Footer */}
            <div className="sticky bottom-0 bg-white shadow-md">
                <CombinedFooter />
            </div>
        </div>
    );
};

export default Pricing;
