import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { MdWorkspacePremium } from 'react-icons/md';
import Slider from 'react-slick';
import { EbookImgUrl} from '../../Constants/Config';
import { useNavigate } from 'react-router-dom';
import { getSession } from '../../lib/session';
import toast from 'react-hot-toast';

function Ebooks(props) {
  const navigate = useNavigate();
  const session = getSession();
  const isPremiumUser = session.isPremiumUser; // Check if the user is premium

  const settings = {
    centerMode: false,
    centerPadding: '0px',
    slidesToShow: 4,
    arrows: true,
    dots: false,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 1,
        },
      },
    ],
  };

  const handlePdfClick = (Data) => {
    if (isPremiumUser || Data.IsPremium !== 1) {
      navigate(`/Ebook/${Data.PdfSecretKey}`); // Allow access to non-premium eBooks
    } else {
      toast.error("Please purchase a package to access premium content.");
    }
  };

  const handleDownload = (Data) => {
    if (isPremiumUser) {
      // If user is premium, allow the download
      const link = document.createElement('a');
      link.href = EbookImgUrl + Data.PdfFileUrl; // Assuming PdfFileUrl contains the download URL
      link.download = Data.Heading; // Setting the file name for download
      link.click();
    } else {
      toast.error("You need a premium account to download this eBook.");
    }
  };

  return (
    <>
      {props.EbookData?.length > 0 && (
        <div className="trendingvideos">
          <div className="container">
            <div className="row" style={{ clear: "both", display: "flex" }}>
              <div
                className="col-lg-10 col-md-8 col-sm-12"
                onClick={() => navigate(`/Group/${props.GroupData.Id}/Type/13`)}
              >
                <h2 className="Group-Heading">{props.GroupData.Heading}</h2>
              </div>
              {props.EbookData?.length > 4 && (
                <div className="col-lg-2 col-md-4 ">
                  <button
                    className="viewall-btn"
                    onClick={() =>
                      navigate(`/Group/${props.GroupData.Id}/Type/13`)
                    }
                  >
                    View All
                  </button>
                </div>
              )}
            </div>
            <Slider {...settings}>
              {props.EbookData?.map((data, index) => (
                <div className="video-item" key={index}>
                  <button
                    className={`trendvideo-item  ${
                      data.IsPremium === 1 ? "premium-icon" : ""
                    } ${data.IsCopyRight === 1 ? "copy-icon" : ""}`}
                    style={{ position: "relative" }}
                    onClick={() => handlePdfClick(data)}
                  >
                    <span className="premium-icon-span">
                      <img
                        src={EbookImgUrl + data.ThumbImg}
                        alt="peacegate"
                        style={{ height: "300px", width: "100%" }}
                      />
                    </span>
                    <div
                      className="videocontent"
                      style={{
                        padding: "0px",
                        transitionDuration: "0s",
                        height: "80px",
                      }}
                    >
                      <h6>{data.Heading}</h6>
                    </div>
                  </button>

                  {/* Show download button only for premium users and for premium content */}
                  {/* {data.IsPremium === 1 && isPremiumUser && (
                    <button
                      onClick={() => handleDownload(data)}
                      className="download-btn"
                    >
                      Download
                    </button>
                  )} */}
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )}
    </>
  );
}

export default Ebooks;
