import React, { useMemo, useState } from "react";
import { NavDropdown, Dropdown } from "react-bootstrap";
import PrithviLogo from "../../img/the-prithvi-logo.png";
import { useNavigate } from "react-router-dom";
import { clearSession, getSession } from "../../lib/session";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckMultiLoginError,
  GetUserError,
  LogoutMultiUser,
} from "../../Redux/Authentication/action";
import ToggleButton from "../Button/ToggleButton";
import {
  GetNotificationStatus,
  PushNotificationUpdate,
} from "../../Redux/UserHome/action";
import { useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import { useWindowSize } from "../../lib/hooks/useWindowsize";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { height, width } = useWindowSize();
  const [isForumNotificationOn, setIsForumNotificationOn] = useState(true); // State for forum notifications
  const [isGeneralNotificationOn, setIsGeneralNotificationOn] = useState(true); // State for general notifications
  const [hasCollapsingClass, setHasCollapsingClass] = useState(false);

  const handleButtonClick = () => {
    setHasCollapsingClass((prevState) => !prevState);
  };

  useEffect(() => {
    console.log("hasCollapsing", hasCollapsingClass);
  }, [hasCollapsingClass]);
  const redirect = useNavigate();
  const dispatch = useDispatch();
  const session = getSession();
  const IsReadResponse = useSelector(
    (state) => state.UserHomeReducer.ReadNotification,
  );
  const NotifiactionUpdateResponse = useSelector(
    (state) => state.UserHomeReducer.PushnotficationUpdate,
  );
  const NotificationStatus = useSelector(
    (state) => state.UserHomeReducer.GetNotificationStatus,
  );

  useEffect(() => {
    dispatch(
      GetNotificationStatus({
        UId: session.id,
      }),
    );
  }, []);
  useEffect(() => {
    console.log('NotificationStatus:', NotificationStatus); // Log the entire NotificationStatus
  
    if (NotificationStatus && NotificationStatus.length > 0) {
      const IsForumNotification =
        NotificationStatus[0].IsForumCommentOn === 0 ? false : true;
      const IsArticleNotification =
        NotificationStatus[0].IsArticleComments === 0 ? false : true;
  
      setIsForumNotificationOn(IsForumNotification);
      setIsGeneralNotificationOn(IsArticleNotification);
    } else {
      console.warn('NotificationStatus is undefined or empty'); // Log a warning if the condition is not met
      setIsForumNotificationOn(false);
      setIsGeneralNotificationOn(false);
    }
  }, [NotificationStatus]);
  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure you want to logout?");
    if (confirmLogout) {
      dispatch(
        LogoutMultiUser({
          SecretKey: session?.SecretKey,
          UserId: session?.id,
        }),
      );
      dispatch(CheckMultiLoginError());
      dispatch(GetUserError());
      clearSession();
      redirect("/");
    }
  };

  const handleForumNotificationToggle = () => {
    const userConfirmed = window.confirm(
      `Are you sure you want to ${isForumNotificationOn ? "turn off" : "turn on"} forum notifications?`,
    );

    if (userConfirmed) {
      dispatch(
        PushNotificationUpdate({
          Type: 1,
          IsTurnOn: isForumNotificationOn ? 0 : 1,
          UserId: session.id,
        }),
      );
      setIsForumNotificationOn(!isForumNotificationOn);
    }
  };

  const handleGeneralNotificationToggle = () => {
    const userConfirmed = window.confirm(
      `Are you sure you want to ${isGeneralNotificationOn ? "turn off" : "turn on"} general notifications?`,
    );

    if (userConfirmed) {
      dispatch(
        PushNotificationUpdate({
          Type: 2,
          IsTurnOn: isGeneralNotificationOn ? 0 : 1,
          UserId: session.id,
        }),
      );
      setIsGeneralNotificationOn(!isGeneralNotificationOn);
    }
  };

  const handleItemClick = (event) => {
    event.stopPropagation();
  };
  return (
    <div className="header-top" id="top">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3">
            <img
              src={PrithviLogo}
              alt="The Prithvi | Logo"
              className="header-logo"
            />
          </div>
          <div className="col-lg-9">
            {/* <nav className="navbar navbar-expand-lg navbar-light">
              <button type="button" style={{ color: "#f2d9c2", border: 0 }}>
                <RxHamburgerMenu
                  className="navbar-toggler"
                  size={40}
                  role="button"
                  style={{ padding: 0, color: "#f2d9c2", border: 0 }}
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={handleButtonClick}
                />
                
              </button>
              <div
                className={`navbar-collapse ${hasCollapsingClass ? "" : "collapsing"}`}
                id="navbarSupportedContent"
                style={{ height: "100%" }}
              >
                <ul className="navbar-nav">
                  <IoClose
                    className="btn clost-btn"
                    size={60}
                    style={{ color: "#f2d9c2", border: 0 }}
                    onClick={handleButtonClick}
                  />
                  <li className="nav-item">
                    <a className="nav-link" href="#about">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/Contact-Us">
                      Contact Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/packages">
                      Premium
                    </a>
                  </li>
                  <li className="nav-item">
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Services"
                    >
                      <NavDropdown.Item href="/Type/1">
                        Articles
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/Type/2">Videos</NavDropdown.Item>
                      <NavDropdown.Item href="/Type/3">
                        Puzzles
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/Type/6">Ebook</NavDropdown.Item>
                      <NavDropdown.Item href="/Type/4">
                        Posters
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/Type/8">Audios</NavDropdown.Item>
                    </NavDropdown>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/drug">
                      Yogam
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/forum">
                      Forum
                    </a>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link notification-bell `}
                      style={{ fontSize: "20px" }}
                      onClick={() => redirect("/notification")}
                    >
                      <i className="fa-solid fa-bell"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </nav> */}
            <nav className="navbar navbar-expand-lg navbar-light">
              <button
                className="navbar-toggler"
                role="button"
                style={{
                  padding: 0,
                  color: "#f2d9c2",
                  border: 0,
                  marginRight: "10px",
                }}
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={handleButtonClick}
              >
                <RxHamburgerMenu
                  size={40}
                  role="button"
                  style={{ padding: 0, color: "#f2d9c2", border: 0 }}
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                />
              </button>
              <div
                className={`navbar-collapse ${width <= 995 && !hasCollapsingClass ? "collapsing" : ""}`}
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <IoClose
                      className="btn close_btn"
                      role={"button"}
                      size={40}
                      style={{ color: "#f2d9c2", border: 0 }}
                      onClick={handleButtonClick}
                    />
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#about">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/Contact-Us">
                      Contact Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/packages">
                      Get Premium
                    </a>
                  </li>
                  <li className="nav-item">
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Services"
                    >
                      <NavDropdown.Item href="/Type/1">
                        Articles
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/Type/2">Videos</NavDropdown.Item>
                      <NavDropdown.Item href="/Type/3">
                        Puzzles
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/Type/6">Ebook</NavDropdown.Item>
                      <NavDropdown.Item href="/Type/4">
                        Posters
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/Type/8">Podcast</NavDropdown.Item>
                    </NavDropdown>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/drug">
                      Yogam
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/forum">
                      Forum
                    </a>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link notification-bell `}
                      style={{ fontSize: "20px" }}
                      onClick={() => redirect("/notification")}
                    >
                      <i className="fa-solid fa-bell"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </nav>
            <Dropdown className="profile-icon">
              <Dropdown.Toggle
                id="dropdownMenuButton"
                type="button"
              ></Dropdown.Toggle>
              <Dropdown.Menu align="right">
                <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                <Dropdown.Item href="/chat">AskGuru</Dropdown.Item>
                <Dropdown.Item onClick={handleItemClick}>
                  <ToggleButton
                    isOn={isForumNotificationOn}
                    handleToggle={handleForumNotificationToggle}
                    label="Forum"
                  />
                </Dropdown.Item>
                <Dropdown.Item onClick={handleItemClick}>
                  <ToggleButton
                    isOn={isGeneralNotificationOn}
                    handleToggle={handleGeneralNotificationToggle}
                    label="General"
                  />
                </Dropdown.Item>

                <Dropdown.Divider />
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
