import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { GetGroupDataByType, ResetGroupData } from "../../Redux/UserHome/action"; // Add ResetGroupData action
import "../Css/AllGroup.css";
import CombinedFooter from "../../Components/Footer/CombinedFooter";
import useHardwareBackButton from "../../Constants/HardwareBackButtonPress";
import Articles from "../../Components/UserHome/Articles";
import VideoSlide from "../../Components/UserHome/VideoSlide";
import ImageSlide from "../../Components/UserHome/ImageSlide";
import BannerSlide from "../../Components/UserHome/BannerSlide";
import AudioSlide from "../../Components/UserHome/AudioSlide";
import Ebooks from '../../Components/UserHome/Ebooks';
import axios from 'axios';
import ApiClient from "../../Services/ApiClient";
import { ApiUrl } from "../../Constants/Config";
import { startLoader, stopLoader } from "../../Redux/Common/Loader/action";

function AllHerbs() {  // Changed to AllHerbs
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [VideoModal, setVideoModal] = useState(false);
  const [AudioModal, setAudioModal] = useState(false);
  const [OpenImage, setOpenImage] = useState(false);
  const [groupDataByType, setGroupDataByType] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredHeadings, setFilteredHeadings] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isShareModalOpen, setShareModalOpen] = useState(false);

  useEffect(() => {
    if (searchTerm) {
      const allHeadings = groupDataByType
        ?.flatMap((group) => group.Data.map((item) => item.Heading)) || [];

      const filtered = allHeadings.filter((heading) =>
        heading.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setFilteredHeadings(filtered);
      setDropdownVisible(true);
    } else {
      setFilteredHeadings([]);
      setDropdownVisible(false);
    }
  }, [searchTerm, groupDataByType]);

  const handleOptionSelect = (heading) => {
    setSearchTerm(heading);
    setDropdownVisible(false);
  };

  const handleShareClick = () => setShareModalOpen(true);
  const closeShareModal = () => setShareModalOpen(false);

  const appUrl = window.location.href;
  const shareTitle = groupDataByType?.Heading || 'Check out this AllHerbs content!';
  const encodedUrl = encodeURIComponent(appUrl);
  const encodedTitle = encodeURIComponent(shareTitle);

  const handleBackButtonPress = () => {
    if (VideoModal || AudioModal || OpenImage) {
      setVideoModal(false);
      setAudioModal(false);
      setOpenImage(false);
    } else {
      navigate('/user-home');
    }
  };

  useHardwareBackButton(0, handleBackButtonPress);

  const { Id } = useParams();
  const [GroupType, setGroupType] = useState(null);

  const GroupDataByType = useSelector(
    (state) => state.UserHomeReducer.GroupDataByType,
  );

  useEffect(() => {
    // Fetch group data based on type using Axios
    const fetchData = async () => {
      dispatch(startLoader())
      try {
        const response = await axios.get(ApiUrl + "AllHerbs?type=9&dummy=1");  // Updated URL
        setGroupDataByType(response.data);
        dispatch(stopLoader())
      } catch (error) {
        console.error("Error fetching group data:", error);
      }
    };

    fetchData();

    // Clean up data on unmount
    return () => {
      setGroupDataByType([]);
    };
  }, []);

  useEffect(() => {
    // Set GroupType based on URL params (Id)
    if (Id == 1) {
      setGroupType({
        Label: "Herbal Articles",
        Value: 1,
      });
    } else if (Id == 2) {
      setGroupType({ Label: "Herbal Videos", Value: 2 });
    } else if (Id == 3) {
      setGroupType({ Label: "Herbal Puzzles", Value: 3 });
    } else if (Id == 6) {
      setGroupType({
        Label: "Herbal Ebooks",
        Value: 6,
      });
    } else if (Id == 4) {
      setGroupType({
        Label: "Herbal Posters",
        Value: 4,
      });
    } else if (Id == 8) {
      setGroupType({ Label: "Herbal Audios", Value: 8 });
    }

    // When component mounts, reset group data
    return () => {
      dispatch(ResetGroupData()); // Clear group data on unmount or navigation
    };
  }, [Id, dispatch]);

  const filteredGroupData = groupDataByType && groupDataByType.map(group => {
    // Map over each group and filter item.Data
    const filteredData = group.Data.filter(item => {
      // Filter based on Heading or Keyword
      return (
        item.Heading.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.Keyword.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    // Return the group with filtered data
    return {...group, Data: filteredData};
  });

  const OrderedPrincipleGroup = filteredGroupData?.map(item => {
    const { GroupId, GroupName, Data } = item;
    const GroupData = {
      Id: GroupId,
      Heading: GroupName
    };

    const uniqueKey = `${GroupId}_${1}`;
   
    switch (1) {
      case 1: // Articles
        const ArticleData = Data;
        return (
          <Articles
            key={uniqueKey}  // Use a unique key
            GroupData={GroupData}
            ArticleData={ArticleData}
          />
        );
      case 2: // Videos
        const VideoData = Data;
        return (
          <VideoSlide
            key={uniqueKey}  // Use a unique key
            GroupData={GroupData}
            VideoData={VideoData}
          />
        );
      case 3: // Puzzles
        const ImageData = Data;
        return (
          <ImageSlide
            key={uniqueKey}  // Use a unique key
            GroupData={GroupData}
            ImageData={ImageData}
          />
        );
      case 4: // Posters
        const BannerData = Data;
        return (
          <BannerSlide
            key={uniqueKey}  // Use a unique key
            GroupData={GroupData}
            BannerData={BannerData}
          />
        );
      case 6: // Ebooks
        const EbookData = Data;
        return (
          <Ebooks
            key={uniqueKey}  // Use a unique key
            GroupData={GroupData}
            EbookData={EbookData}
          />
        );
      case 8: // Audios
        const AudioData = Data;
        return (
          <AudioSlide
            key={uniqueKey}  // Use a unique key
            GroupData={GroupData}
            AudioData={AudioData}
          />
        );
      default:
        return null;
    }
  });

  return (
    <div className="ContentDisplayPage">
      <div className="Article-Modal" style={{ flex: '1 1 0%' }}>
        <div className="home-slider">
          <div className="page-header-con">
            <button className="backbtn" onClick={() => navigate("/user-home")}>
              <i className="fas fa-chevron-left"></i>
            </button>
          </div>
        </div>

        <h2 className="page-heading">Herbal Articles</h2>  {/* Updated title */}
        <div className="container">
          <div className="row justify-content-center mt-3">
            <div className="search-container">
              <div className="row w-100" style={{ justifyContent: "center", display: "flex" }}>
                <div className="col-xl-3 col-lg-3 col-md-3 col-4" style={{ paddingLeft: "0px !important" }}>
                  <select
                    style={{ borderRight: "none", appearance: 'auto', paddingRight: '0px' }}
                    name="search-options"
                    id="search-select"
                    value={GroupType?.Value}
                    onChange={(e) => {
                      const selectedValue = parseInt(e.target.value);  // Convert to integer
                      switch (selectedValue) {
                        case 1:
                          navigate("/Type/1");
                          break;
                        case 2:
                          navigate("/Type/2");
                          break;
                        case 3:
                          navigate("/Type/3");
                          break;
                        case 6:
                          navigate("/Type/6");
                          break;
                        case 4:
                          navigate("/Type/4");
                          break;
                        case 8:
                          navigate("/Type/8");
                          break;
                          case 4:
                            navigate("/Type/9");
                            break;
                          case 8:
                            navigate("/Type/10");
                            break;
                        default:
                          break;
                      }
                    }}
                  >
                    <option value="1">Herbal Articles</option>
                    <option value="2">Herbal Videos</option>
                    <option value="3">Herbal Puzzles</option>
                    <option value="6">Herbal Ebooks</option>
                    <option value="4">Herbal Posters</option>
                    <option value="8">Herbal Audios</option>
                  </select>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-6 col-6">
                  <input
                    style={{
                      borderTopRightRadius: "20px",
                      borderBottomRightRadius: "20px",
                    }}
                    type="text"
                    placeholder={`Search ${GroupType?.Label}...`}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  {/* Dropdown List */}
                  {dropdownVisible && filteredHeadings.length > 0 && (
                    <ul
                      style={{
                        position: "absolute",
                        top: "40px",
                        left: "0",
                        width: "100%",
                        maxHeight: "200px",
                        overflowY: "auto",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        backgroundColor: "#fff",
                        zIndex: 1000,
                        listStyleType: "none",
                        padding: "0",
                        margin: "0",
                      }}
                    >
                      {filteredHeadings.map((heading, index) => (
                        <li
                          key={index}
                          onClick={() => handleOptionSelect(heading)}
                          style={{
                            padding: "10px",
                            cursor: "pointer",
                            borderBottom: "1px solid #f0f0f0",
                          }}
                        >
                          {heading}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container" style={{ paddingBottom: '80px' }}>
          {OrderedPrincipleGroup && OrderedPrincipleGroup.length > 0 ? OrderedPrincipleGroup : <div style={{ textAlign: 'center', marginTop: '50px' }}> No Data Found</div>}
        </div>
      </div>

      <CombinedFooter />
    </div>
  );
}

export default AllHerbs;
