import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CombinedFooter from '../../Components/Footer/CombinedFooter';
import { getSession } from '../../lib/session';
import { GetAllPackgaes } from '../../Redux/UserHome/action';
import '../Css/Packages.css';
import Package from './Package';
import useHardwareBackButton from '../../Constants/HardwareBackButtonPress';
import ApiClient from '../../Services/ApiClient';
import axios  from 'axios';
import { ApiUrl } from '../../Constants/Config';

// Function to load Razorpay script asynchronously
const loadRazorpayScript = () => {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
};

// Function to display Razorpay payment form

const displayRazorpay = async (packageData) => {
    console.log(packageData, 'uswdbhsubh');
    const res = await loadRazorpayScript();

    if (!res) {
        alert('Failed to load Razorpay SDK. Are you online?');
        return;
    }

    const userDetails = localStorage.getItem('authToken');
    console.log(userDetails,"userDetails");
    if (!userDetails) {
        alert('User not logged in');
        return;
    }
    // Prepare the payload for the API request
    const payload = {
        id: 0,
        PackageId: packageData.Id,
        Amount: packageData.Amount,
        Duration: packageData.Duration,
        UserId: JSON.parse(userDetails).id,
        AdminId: 10

        // Include any other necessary fields for the order creation
    };

    // Create the order using the API
    const response = await ApiClient.post('UserPackageMapping', payload);
    console.log(response.data, 'rasss');
    // Check for success in the response
    if (response.data[0].Error === 0 && response.data[0].Id) {
        const options = {
            key: 'rzp_live_677wvEzQAUpm3o', // Replace with your Razorpay key
            // rzp_live_677wvEzQAUpm3o
            amount: packageData.Amount * 100, // Price is in paise
            currency: 'INR',
            name: 'PRITHVI',
            description: `Payment for ${packageData.name}`,
            image: 'https://your-logo-url.com', // Replace with your logo URL
            order_id: response.id, // Set the order ID from the response
            handler: async function (razorpayResponse) {
                const Id = response.data[0].Id; // Order ID from the first API response
                const PaymentCode = razorpayResponse.razorpay_payment_id; // Transaction ID from Razorpay
                const TotalAmount = packageData.Amount; // Total amount

                // Construct the URL for the GET request
                const captureUrl = `Forum/PaymentCapture?id=${Id}&PaymentCode=${PaymentCode}&TotalAmount=${TotalAmount}`;

                // Call the payment capture API
                const captureResponse = await ApiClient.get(captureUrl);
                console.log(' capturing payment:', captureResponse.data);

                if (captureResponse.data.Error === 0) {
                    alert('Payment captured successfully.');
                    // Prepare updated user details
                    const currentUserDetails = JSON.parse(userDetails);
                    const updatedUserDetails = {
                        ...currentUserDetails,
                        planExpiresAt: captureResponse.data.planExpiresAt
                            ? captureResponse.data.planExpiresAt
                            : JSON.parse(userDetails).planExpiresAt // Update planExpiresAt
                    };
                    console.log(updatedUserDetails, 'after===');

                    // Check for planExpiresAt and update isPremiumUser
                    if (captureResponse.data.planExpiresAt) {
                        const planExpiresAt = new Date(
                            captureResponse.data.planExpiresAt
                        );
                        const now = new Date();

                        updatedUserDetails.isPremiumUser = planExpiresAt > now; // Set isPremiumUser based on the date
                    }

                    // Save updated details to local storage
                    localStorage.setItem(
                        'authToken',
                        JSON.stringify(updatedUserDetails)
                    );
                } else {
                    alert('Failed to capture payment. Please try again.');
                }
            },

            prefill: {
                name: JSON.parse(userDetails).name,
                email: JSON.parse(userDetails).email,
                contact: JSON.parse(userDetails).mobile
            },
            theme: {
                color: '#AD7B42'
            }
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    } else {
        alert('Failed to create order. Please try again.');
    }
};

function Packages() {
    const [userPackage, setUserPackage] = useState([]);



    const navigate = useNavigate();

    // Handle back button press using hardware button hook
    const handleBackButtonPress = () => {
        navigate('/user-home');
    };

    

    // Custom hook to handle hardware back button press
    useHardwareBackButton(0, handleBackButtonPress);

    // Get the session for the logged-in user
    const session = getSession();

    // Get the packages data from Redux store
    const PackagesData = useSelector(
        (state) => state.UserHomeReducer.GetAllPackages
    );

    const dispatch = useDispatch();

    // Fetch all packages when the component loads
    useEffect(() => {
        dispatch(GetAllPackgaes());
    }, [dispatch]);

    // Function to initiate package purchase via Razorpay
    const PackagePurchase = (data) => {
        displayRazorpay(data); // Pass the package data (with price) to the Razorpay function
    };

    // USEEFFECT
    useEffect(() => {

         const userDetails = localStorage.getItem('authToken');
         const usd = JSON.parse(userDetails)

          console.log(usd.id,'usd');
            const userPackages = async () =>{
                try{
                    const response = await axios.get(
                        ApiUrl+
                        `UserPackageMapping?userId=${usd.id}`
                    );
                    if(response){
                        setUserPackage(response.data)
                        console.log(userPackage, 'respo');
                    }else{

                    }   
                 
                }catch{
                    console.error('Error fetching user packages');
                }
            }
            userPackages();

      }, [dispatch]);

    return (
        <>
            <div className="Article-Modal ContentDisplayPage">
                <div className="home-slider">
                    <div className="page-header-con">
                        <button
                            className="backbtn"
                            onClick={handleBackButtonPress}
                        >
                            <i className="fas fa-chevron-left"></i>
                        </button>
                    </div>
                </div>
                <h2 className="page-heading">
                    <i className="fa-solid fa-certificate"></i>My Packages
                </h2>

                {/* New section ********************************************************************************* */}

                <div className="report-sections mx-auto flex flex-col items-start justify-start">
                    {/* <h2 className="report-heading">    Reports</h2> */}
                    {/* <button
                        className="btn mt-2 transition-transform duration-300 ease-in-out hover:scale-105 hover:bg-green-500"
                        style={{ backgroundColor: 'green', color: '#fff' }}
                        onClick={() => navigate('/My')}
                    >
                        MY PACKAGES
                    </button> */}
                </div>

                <div className="div1"></div>
                <div className="div2 border-t-2 border-gray-300"></div>

                <div
                    className="Packages container mt-3"
                    style={{ marginBottom: '60px' }}
                >
                    <div className="row mb-4">
                        {userPackage && userPackage.length > 0 ? (
                            userPackage.map((data, index) => (
                                <div
                                    className="col-xl-6 col-lg-6 mb-5 mt-3"
                                    key={index}
                                >
                                    <Package {...data} />
                                    {/* <button
                                        className="btn mt-2"
                                        style={{
                                            backgroundColor: '#ad7b42',
                                            color: '#fff'
                                        }}
                                        onClick={() => PackagePurchase(data)}
                                    >
                                       Purchase
                                    </button> */}
                                </div>
                            ))
                        ) : (
                            <div className="col-12 text-center">
                                <p
                                    style={{
                                        textAlign: 'center',
                                        color: 'gray',
                                        fontSize: '18px',
                                        marginTop: '20px'
                                    }}
                                >
                                    No data available.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                <h2 className="page-heading">
                    <i className="fa-solid fa-certificate"></i> Packages
                </h2>

                {/* New section ********************************************************************************* */}

                <div className="report-sections mx-auto flex flex-col items-start justify-start">
                    {/* <h2 className="report-heading">    Reports</h2> */}
                    {/* <button
                        className="btn mt-2 transition-transform duration-300 ease-in-out hover:scale-105 hover:bg-green-500"
                        style={{ backgroundColor: 'green', color: '#fff' }}
                        onClick={() => navigate('/My')}
                    >
                        MY PACKAGES
                    </button> */}
                </div>

                <div className="div1"></div>
                <div className="div2 border-t-2 border-gray-300"></div>

                <div
                    className="Packages container mt-3"
                    style={{ marginBottom: '60px' }}
                >
                    <div className="row mb-4">
                        {PackagesData && PackagesData.length > 0 ? (
                            PackagesData.map((data, index) => (
                                <div
                                    className="col-xl-6 col-lg-6 mb-5 mt-3"
                                    key={index}
                                >
                                    <Package {...data} />
                                    <button
                                        className="btn mt-2"
                                        style={{
                                            backgroundColor: '#ad7b42',
                                            color: '#fff'
                                        }}
                                        onClick={() => PackagePurchase(data)}
                                    >
                                       Purchase
                                    </button>
                                </div>
                            ))
                        ) : (
                            <div className="col-12 text-center">
                                <p
                                    style={{
                                        textAlign: 'center',
                                        color: 'gray',
                                        fontSize: '18px',
                                        marginTop: '20px'
                                    }}
                                >
                                    No data available.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <CombinedFooter />
        </>
    );
}

export default Packages;
