import React, { useEffect, useState } from 'react';
import { GetSingleArticle } from '../../Redux/UserHome/action';
import { ArticleImgUrl } from '../../Constants/Config';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSession } from '../../lib/session';
import CombinedFooter from '../../Components/Footer/CombinedFooter';
import DesktopFooter from '../../Components/Footer/DesktopFooter';

function SingleArticle() {
    const { Id } = useParams();
    const session = getSession();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isShareModalOpen, setShareModalOpen] = useState(false);

    const SingleArticleData = useSelector(
        (state) => state.UserHomeReducer.GetSigleArticle
    );

    const isPremiumUser = session && session.isPremiumUser;
    const isPremiumArticle =
        SingleArticleData && SingleArticleData.IsPremium !== 1;

    useEffect(() => {
        if (Id) {
            dispatch(
                GetSingleArticle({
                    Id: Id
                })
            );
            window.scrollTo(0, 0);
        }
    }, [Id]);

    const handleShareClick = () => setShareModalOpen(true);
    const closeShareModal = () => setShareModalOpen(false);

    const appUrl = window.location.href;
    const shareTitle = SingleArticleData?.Heading || 'Check out this article!';
    const encodedUrl = encodeURIComponent(appUrl);
    const encodedTitle = encodeURIComponent(shareTitle);

    return (
        <div className="EditModal overflow-x-hidden">
            {SingleArticleData && (
                <>
                    <div className="Article-Modal ContentDisplayPage">
                        {session && session.id && (
                            <div className="home-slider">
                                <div className="page-header-con flex items-center justify-end bg-gray-100 px-4 py-2">
                                    {/* Back button on the left */}
                                    <div>
                                        <button
                                            className="backbtn text-gray-600 hover:text-gray-800"
                                            onClick={() =>
                                                navigate('/user-home')
                                            }
                                        >
                                            <i className="fas fa-chevron-left"></i>
                                        </button>
                                    </div>

                                    {/* Add a central element if needed */}
                                    <div className="text-center">
                                        <button
                                            className="bg-[#b78751] text-[#b78751] hover:bg-[#b78751] hover:text-[#b78751]"
                                            onClick={handleShareClick}
                                        >
                                            <i
                                                className="fa-solid fa-share-alt"
                                                style={{ color: '#b78751' }}
                                            ></i>{' '}
                                            <span style={{ color: '#b78751' }}>
                                                Share
                                            </span>
                                        </button>
                                    </div>

                                    {/* Back button on the right
                                    <button
                                        className="backbtn text-gray-600 hover:text-gray-800"
                                        onClick={() => navigate('/user-home')}
                                    >
                                        <i className="fas fa-chevron-left"></i>
                                    </button> */}
                                </div>
                            </div>
                        )}
                        <h2 className="mt-4 text-center text-2xl font-semibold">
                            {SingleArticleData?.Heading}
                        </h2>
                        <div className="mt-4 flex justify-center">
                            <img
                                className="h-auto max-w-full"
                                src={
                                    ArticleImgUrl + SingleArticleData?.ImageUrl
                                }
                                alt="The Prithvi"
                            />
                        </div>

                        {isPremiumUser || isPremiumArticle ? (
                            SingleArticleData?.LinkUrl && (
                                <div className="mt-4 flex justify-center">
                                    <a
                                        href={SingleArticleData.LinkUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="px-4 py-2 text-blue-600 hover:underline"
                                    >
                                        Reference Link
                                        <i className="fa-solid fa-link ml-2"></i>
                                    </a>
                                </div>
                            )
                        ) : (
                            <div className="mt-4 flex justify-center">
                                <button
                                    onClick={() => navigate('/')}
                                    className="rounded bg-yellow-500 px-4 py-2 text-white hover:bg-yellow-600"
                                >
                                    Login to view
                                </button>
                            </div>
                        )}

                        <div className="service-single mt-4 px-4">
                            {isPremiumUser || isPremiumArticle ? (
                                <p
                                    className="text-gray-700"
                                    dangerouslySetInnerHTML={{
                                        __html: SingleArticleData?.Description
                                    }}
                                />
                            ) : (
                                <p className="text-center font-bold text-red-600">
                                    Please login to view this premium article!
                                </p>
                            )}
                        </div>
                    </div>
                    {session && session.id ? (
                        <CombinedFooter />
                    ) : (
                        <DesktopFooter />
                    )}
                </>
            )}

            {/* Share Modal */}
            {isShareModalOpen && (
                <div
                    className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50"
                    onClick={closeShareModal} // Close modal on clicking the backdrop
                >
                    <div
                        className="relative w-full max-w-sm rounded-lg bg-white p-4"
                        onClick={(e) => e.stopPropagation()} // Prevent modal close when clicking inside the modal
                    >
                        {/* Modal Header */}
                        <h3 className="text-center text-lg font-semibold">
                            Share
                        </h3>
                        <button
                            className="absolute right-4 top-4 text-gray-600 hover:text-gray-800"
                            onClick={closeShareModal} // Close modal on clicking the button
                        >
                            <i className="fas fa-times"></i>
                        </button>

                        {/* Top Section: Social Media Icons */}
                        <div className="mt-4 flex justify-around">
                            <a
                                href={`https://wa.me/?text=${encodedTitle}%20${encodedUrl}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex flex-col items-center text-green-500 hover:text-green-600"
                            >
                                <i className="fab fa-whatsapp text-3xl"></i>
                                <span className="mt-1 text-sm">WhatsApp</span>
                            </a>
                            <a
                                href={`https://www.instagram.com/?url=${encodedUrl}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex flex-col items-center text-pink-500 hover:text-pink-600"
                            >
                                <i className="fab fa-instagram text-3xl"></i>
                                <span className="mt-1 text-sm">Instagram</span>
                            </a>
                            <a
                                href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex flex-col items-center text-blue-500 hover:text-blue-600"
                            >
                                <i className="fab fa-facebook text-3xl"></i>
                                <span className="mt-1 text-sm">Facebook</span>
                            </a>
                            <a
                                href={`https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedUrl}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex flex-col items-center text-blue-400 hover:text-blue-500"
                            >
                                <i className="fab fa-twitter text-3xl"></i>
                                <span className="mt-1 text-sm">Twitter</span>
                            </a>
                        </div>

                        {/* Divider */}
                        <div className="my-4 border-t border-gray-300"></div>

                        {/* Bottom Section: URL with Copy Button */}
                        <div className="flex items-center justify-between rounded bg-gray-100 p-2">
                            <input
                                type="text"
                                value={appUrl}
                                readOnly
                                className="flex-1 bg-transparent text-gray-800 outline-none"
                            />
                            <button
                                onClick={async () => {
                                    await navigator.clipboard.writeText(appUrl);
                                    alert('Link copied to clipboard!');
                                }}
                                className="ml-2 rounded bg-blue-500 px-4 py-2 text-sm text-white hover:bg-blue-600"
                            >
                                Copy
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default SingleArticle;
