import React from "react";
import { MdWorkspacePremium } from "react-icons/md";
import { ArticleImgUrl } from "../../Constants/Config";
import { stripHtmlTags } from "../../lib/helper";
import { formattedDate, FormattedDate2 } from "../../Constants/FormattedDate";
import { getSession } from "../../lib/session";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


function AllArticlesNew(props) {
  const session = getSession();
  const navigate=useNavigate()
  const ArticleClick = (Data) => {
    const isPremiumUser = session.isPremiumUser;
    if (isPremiumUser || Data.IsPremium !== 1) {
      navigate(`/Article/${Data.Id}`)
    } else {
      toast.error("Please purchase a package");
    }
  };
  console.log("props",props);
  return (
    <>
      <div className="row articles-wrap" style={{ width: "unset",paddingTop:'40px'}}>
          {props.filteredArticles?.map((data, index) => (
            <div className="col-lg-3 article-items-parent" key={index}>
            <button
              className={`article-item ${data.IsPremium === 1 ? 'premium-icon' : ''} ${data.IsCopyRight===1?'copy-icon':''}`}
              onClick={() => ArticleClick(data)}
            >
              <span className="Article-span">
                <img
                  src={ArticleImgUrl + data.ImageUrl}
                  alt="peacegate"
                />
                <font className="link-icon">
                  <i className="fas fa-link"></i>
                </font>
                <h6>
                  <font>{formattedDate(data.Date)}</font>
                </h6>
              </span>
              <div className="article-content">
                <h5>{data.Heading}</h5>
                {/* <p>{stripHtmlTags(data.Description, 150)}</p> */}
                {/* <label className='read-more'>read more</label> */}
              </div>
            </button>
          </div>
          ))}
        </div>
    </>
  );
}

export default AllArticlesNew;