import axios from 'axios';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ReactStars from 'react-rating-stars-component'; // Import the rating stars component
import './updatecss.css';
import PrithviLogo from "../../img/the-prithvi-logo.png";

function RatingModal(props) {
  const [rating, setRating] = useState(0); // State for the rating value
  const [PlaystoreLink, setPlaystoreLink] = useState('https://play.google.com/store/apps/details?id=com.init.prithviapp');
  const [feedback, setFeedback] = useState('');

  // Handle the rating change
  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  // Handle feedback change
  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  // Handle submission of the rating and feedback
  const handleSubmitRating = () => {
    if (rating === 0) {
      alert('Please rate the app before submitting.');
      return;
    }

    // Send the rating and feedback (if any) to your server or analytics service
    axios.post('/submit-rating', { rating, feedback })
      .then(response => {
        console.log('Rating submitted:', response.data);
        // Close the modal after submission
        props.close();
      })
      .catch(error => {
        console.error('Error submitting rating:', error);
      });
  };

  const handleUpdateClick = () => {
    window.location.href = PlaystoreLink;
  };

  return (
    <Modal show={props.Show} size="sm" onHide={props.close} centered>
      <Modal.Body className="alert-boxwrap updatecancel-btn">
        <img
          src={PrithviLogo}
          alt="Prithvi Logo"
          style={{
            width: 'auto', // Adjust width as needed
            height: '100%', // Maintain aspect ratio
          }}
        />
        <h5>Rate The Prithvi App</h5>
        <p>We value your feedback! Please rate the app and let us know how we can improve.</p>

        <div className="rating-stars">
          <ReactStars
            count={5}
            onChange={handleRatingChange}
            size={24}
            activeColor="#ffd700"
            value={rating}
          />
        </div>

        <div className="feedback-input">
          <textarea
            rows="4"
            placeholder="Share your feedback (optional)"
            value={feedback}
            onChange={handleFeedbackChange}
            style={{
              width: '100%',
              padding: '8px',
              marginTop: '10px',
              borderRadius: '4px',
              border: '1px solid #ccc',
            }}
          ></textarea>
        </div>

        <div className="row">
          <div className="col-12">
            <button
              className="updatebtn"
              style={{
                width: '100%',
                background: '#b78751',
                color: '#fff',
                marginBottom: '15px',
              }}
              onClick={handleUpdateClick}
            >
              Update Now
            </button>
          </div>

          <div className="col-12">
            <button
              className="submit-rating-btn"
              style={{
                width: '100%',
                background: '#4CAF50',
                color: '#fff',
                padding: '10px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              onClick={handleSubmitRating}
            >
              Submit Rating
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default RatingModal;
