import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { MdClose, MdVolumeOff, MdVolumeUp, MdShare } from "react-icons/md";
import { shortsUrl } from "../../Constants/Config";
import useHardwareBackButton from "../../Constants/HardwareBackButtonPress";

const ShortsDemo = () => {
  const [muted, setMuted] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const videoRefs = useRef([]);
  const observer = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const shorts = useSelector((state) => state.ShortsReducer.shorts) || [];
  
  // Get the shared video ID from URL if it exists
  const searchParams = new URLSearchParams(location.search);
  const sharedVideoId = searchParams.get('videoId');

  useHardwareBackButton(0, () => navigate("/user-home"));

  // Set initial index based on shared video
  useEffect(() => {
    if (sharedVideoId && shorts.length > 0) {
      const videoIndex = shorts.findIndex(short => short.Id === sharedVideoId);
      if (videoIndex !== -1) {
        setCurrentIndex(videoIndex);
        // Scroll to the shared video
        videoRefs.current[videoIndex]?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [sharedVideoId, shorts]);

  const toggleMute = () => {
    setMuted((prev) => !prev);
  };

  const getAppUrl = (videoId) => {
    // Replace with your actual app URL scheme
    const appDomain = window.location.origin;
    return `${appDomain}/shorts?videoId=${videoId}`;
  };

  const handleShare = async () => {
    const currentShort = shorts[currentIndex];
    if (currentShort?.VideoUrl) {
      const appUrl = getAppUrl(currentShort.Id);
      const shareData = {
        title: 'Check out this video!',
        text: 'Watch this awesome video in our app!',
        url: appUrl
      };

      if (navigator.share) {
        try {
          await navigator.share(shareData);
        } catch (error) {
          console.error('Error sharing:', error);
          await navigator?.clipboard?.writeText(appUrl);
          alert('Video link copied to clipboard!');
        }
      } else {
        await navigator.clipboard.writeText(appUrl);
        alert('Video link copied to clipboard!');
      }
    }
  };

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = parseInt(entry.target.dataset.index, 10);
          if (entry.isIntersecting && entry.intersectionRatio === 1) {
            setCurrentIndex(index);
            // Update URL without refreshing the page
            const newUrl = getAppUrl(shorts[index].Id);
            window.history.replaceState({}, '', newUrl);
          }
        });
      },
      {
        threshold: 1.0,
      }
    );

    videoRefs.current.forEach((video) => {
      if (video) {
        observer.current.observe(video);
      }
    });

    return () => {
      observer.current.disconnect();
    };
  }, [shorts]);

  useEffect(() => {
    videoRefs.current.forEach((video, index) => {
      if (video) {
        if (index === currentIndex) {
          video.play();
        } else {
          video.pause();
        }
      }
    });
  }, [currentIndex]);

  return (
    <div className="h-screen w-full overflow-y-scroll bg-black snap-y snap-mandatory">
      <div
        className="absolute top-2 left-2 z-50 cursor-pointer rounded-full bg-darkBrown"
        onClick={() => navigate("/user-home")}
      >
        <MdClose className="h-8 w-8 text-white" />
      </div>

      {shorts.map((short, index) => (
        <div
          key={short.Id}
          className="flex h-screen w-full items-center justify-center snap-center"
        >
          {short?.VideoUrl ? (
            <video
              ref={(el) => (videoRefs.current[index] = el)}
              data-index={index}
              src={`${shortsUrl}${short.VideoUrl}`}
              muted={muted}
              className="h-full w-full object-cover"
              loop
            ></video>
          ) : (
            <span className="text-white">Something went wrong!!!</span>
          )}
        </div>
      ))}

      <div className="absolute top-2 right-2 z-50 flex gap-2">
        <div
          className="cursor-pointer rounded-full bg-darkBrown"
          onClick={handleShare}
        >
          <MdShare className="h-8 w-8 text-white" />
        </div>
        <div
          className="cursor-pointer rounded-full bg-darkBrown"
          onClick={toggleMute}
        >
          {muted ? (
            <MdVolumeOff className="h-8 w-8 text-white" />
          ) : (
            <MdVolumeUp className="h-8 w-8 text-white" />
          )}
        </div>
      </div>
    </div>
  );
};

export default ShortsDemo;