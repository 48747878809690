import React, { useEffect, useState } from "react";
import { GetDrugWithId } from "../../Redux/UserHome/action";
import { DrugImgUrl } from "../../Constants/Config";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CombinedFooter from "../Footer/CombinedFooter";
import DesktopFooter from "../Footer/DesktopFooter";

function SingleDrug() {
  const navigate = useNavigate();
  const { Id } = useParams();
  const dispatch = useDispatch();
  const SingleDrugData = useSelector(
    (state) => state.UserHomeReducer.GetDrugWithId
  );
  const [isShareModalOpen, setShareModalOpen] = useState(false);

  useEffect(() => {
    if (Id) {
      dispatch(
        GetDrugWithId({
          DrugId: Id,
          Dummy: 5,
        })
      );
    }
  }, [Id, dispatch]);

  // Handle Share Modal
  const handleShareClick = () => setShareModalOpen(true);
  const closeShareModal = () => setShareModalOpen(false);

  const appUrl = window.location.href;
  const shareTitle = SingleDrugData?.Heading || "Check out this amazing drug!";
  const encodedUrl = encodeURIComponent(appUrl);
  const encodedTitle = encodeURIComponent(shareTitle);

  return (
    <div className="EditModal overflow-x-hidden">
      {SingleDrugData && (
        <>
          <div className="Article-Modal ContentDisplayPage">
            <div className="home-slider">
              <div className="flex justify-between items-center px-4 py-2 bg-gray-100">
                <button
                  className="text-gray-600 hover:text-gray-800"
                  onClick={() => navigate("/drug")}
                >
                  <i className="fas fa-chevron-left"></i> Back
                </button>
                <button
                  className="text-gray-600 hover:text-gray-800"
                  onClick={handleShareClick}
                >
                  <i className="fa-solid fa-share-alt"></i> Share
                </button>
              </div>
            </div>

            <h2 className="text-center text-2xl font-semibold mt-4">
              {SingleDrugData?.Heading}
            </h2>
            <div className="flex justify-center mt-4">
              <img
                className="max-w-full h-auto"
                src={DrugImgUrl + SingleDrugData?.ImageUrl}
                alt="Drug Image"
              />
            </div>
            <div className="service-single px-4 mt-4">
              <p
                dangerouslySetInnerHTML={{
                  __html: SingleDrugData?.Description,
                }}
                className="text-gray-700"
              />
            </div>
          </div>

          {isShareModalOpen && (
            <div
              className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50"
              onClick={closeShareModal} // Close modal on clicking the backdrop
            >
              <div
                className="bg-white rounded-lg w-full max-w-sm p-4 relative"
                onClick={(e) => e.stopPropagation()} // Prevent modal close when clicking inside the modal
              >
                <h3 className="text-lg font-semibold text-center">Share</h3>
                <button
                  className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
                  onClick={closeShareModal}
                >
                  <i className="fas fa-times"></i>
                </button>

                {/* Social Media Share Icons */}
                <div className="flex justify-around mt-4">
                  <a
                    href={`https://wa.me/?text=${encodedTitle}%20${encodedUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex flex-col items-center text-green-500 hover:text-green-600"
                  >
                    <i className="fab fa-whatsapp text-3xl"></i>
                    <span className="text-sm mt-1">WhatsApp</span>
                  </a>
                  <a
                    href={`https://www.instagram.com/?url=${encodedUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex flex-col items-center text-pink-500 hover:text-pink-600"
                  >
                    <i className="fab fa-instagram text-3xl"></i>
                    <span className="text-sm mt-1">Instagram</span>
                  </a>
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex flex-col items-center text-blue-500 hover:text-blue-600"
                  >
                    <i className="fab fa-facebook text-3xl"></i>
                    <span className="text-sm mt-1">Facebook</span>
                  </a>
                  <a
                    href={`https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex flex-col items-center text-blue-400 hover:text-blue-500"
                  >
                    <i className="fab fa-twitter text-3xl"></i>
                    <span className="text-sm mt-1">Twitter</span>
                  </a>
                </div>

                <div className="my-4 border-t border-gray-300"></div>

                {/* Copy URL Section */}
                <div className="flex items-center justify-between bg-gray-100 rounded p-2">
                  <input
                    type="text"
                    value={appUrl}
                    readOnly
                    className="flex-1 text-gray-800 bg-transparent outline-none"
                  />
                  <button
                    onClick={async () => {
                      await navigator.clipboard.writeText(appUrl);
                      alert("Link copied to clipboard!");
                    }}
                    className="ml-2 px-4 py-2 text-sm bg-blue-500 text-white rounded hover:bg-blue-600"
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
          )}

          <CombinedFooter />
        </>
      )}
    </div>
  );
}

export default SingleDrug;
